<template>
  <b-container fluid>
    <div class="d-flex justify-content-center no-dbl-zoom" v-if="loading"><div class="spinner"></div></div>
    <div v-if='showStats'>
    <b-row v-if='statsAvailable' align-h='center'>
      <b-col class='text-center'>
        <b-card :title="team.name + ' v ' + game.opponentName"
                :sub-title="game.location + ' - ' + formatDate(game.startDate)"
                class="text-center">
          <div class="goalieSelect">
            <b-form inline>
              <label for='selectedGoalie' class='goalieSelectLabel'></label>
              <b-form-select id='selectedGoalie'
                             v-model="game.activeGoalie"
                             :options="goalieSelect"
                             class="mb-3">
              </b-form-select>
            </b-form>
          </div>
           <!-- <div class="start-game">
            <b-button @click='startGame()' :disabled='timeSet' variant='success'>Start! {{ startTime }}</b-button>
          </div> -->
          <b-button @click="changePeriod('p1')" class="no-active" variant='primary' v-bind:class="{ 'btn-secondary': periods.p1 }">
            Period 1
          </b-button>
          &nbsp;
          <b-button @click="changePeriod('p2')" class="no-active" variant='primary' v-bind:class="{ 'btn-secondary': periods.p2 }">
            Period 2
          </b-button>
          &nbsp;
          <b-button @click="changePeriod('p3')" class="no-active" variant='primary' v-bind:class="{ 'btn-secondary': periods.p3 }">
            Period 3
          </b-button>

          <div v-for='(hidden, period) in periods' :key='period' class='stat-btn-container'>
            <b-row v-for="stat in orderedStats" :key='stat.name'
                   v-bind:class="{ hidden: hidden }"
                   class='stat-button-row'>
              <b-col>
                <h1><b-badge class='stat-btn' variant='primary'
                          @mousedown="startPress()" @mouseleave="stopPress(stat.name, 'us', period)"
                          @mouseup="stopPress(stat.name, 'us', period)" @touchstart="startPress"
                          @touchend="stopPress(stat.name, 'us', period)" @touchcancel="stopPress(stat.name, 'us', period)" >
                  {{ stat.us[period] }}
                </b-badge></h1>
              </b-col>
              <b-col align-self="center">
                <span class='stat-name'>{{ stat.name }}</span>
              </b-col>
              <b-col>
                <h1><b-badge class='stat-btn' variant='danger'
                          @mousedown="startPress" @mouseleave="stopPress(stat.name, 'them', period)"
                          @mouseup="stopPress(stat.name, 'them', period)" @touchstart="startPress"
                          @touchend="stopPress(stat.name, 'them', period)" @touchcancel="stopPress(stat.name, 'them', period)">
                  {{ stat.them[period] }}
                </b-badge></h1>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if='statsAvailable' class='justify-content-center'>
      <div class='save-btn-container'>
        <b-button type="submit" variant="primary" @click="submit">Save Game Stats!</b-button>
      </div>
    </b-row>
    </div>
    <b-modal static no-close-on-backdrop no-close-on-esc hide-header-close ref="penaltyModal" hide-footer title="Penalty">
      <div class="d-block text-center">
        <h6>Select Player</h6>
        <b-badge
          class='player-btn'
          variant='secondary'
          v-for='player in players' :key='"A" + player.jerseyNumber'
          @click="selectPlayerPenalty(player.jerseyNumber)"
          :id="'pen-player-' + player.jerseyNumber">
          {{ player.jerseyNumber }}
        </b-badge>
        <hr/>
        <h6>Minutes</h6>
        <b-badge class='player-btn penalty-btn'
                  variant='secondary'
                  v-for='tag in penaltyTags' :key='tag'
                  @click="selectPenaltyMinutes(tag)"
                  :id="'pen-' + tag">
          {{ tag }}
        </b-badge>
        <hr/>
        <b-btn class="mr-5" variant="success" @click="addPenalty()">Save</b-btn>
        <b-btn variant="danger" @click="cancelPenalty()">Cancel</b-btn>
      </div>
    </b-modal>
    <b-modal static no-close-on-backdrop no-close-on-esc hide-header-close ref="goalModal" hide-footer title="Goal Details">
      <div class="d-block text-center">
        <h6>Players on Ice</h6>
        <b-badge class='player-btn'
                  v-for='player in players' :key='"B" + player.jerseyNumber'
                  @click="incrementPlayer(player.jerseyNumber)"
                  :id="'goal-player-' + player.jerseyNumber">
          {{ player.jerseyNumber }}
        </b-badge>
      </div>
      <hr/>
      <div class="d-block text-center">
        <h6>Tags</h6>
        <b-badge class='player-btn penalty-btn'
                  v-for='tag in goalTags' :key='tag'
                  @click="addGoalTag(tag)"
                  :id='tag'>
          {{ tag }}
        </b-badge>
      </div>
      <hr/>
      <!-- <div class="d-block text-center">
        <b-row class="justify-content-center">
          <b-col cols="6">
            <h6>Time</h6>
            <b-input-group>
              <b-form-input id="min" type="number" v-model='min' @input="updateTime"/>
              <b-input-group-prepend is-text>
                  :
              </b-input-group-prepend>
              <b-form-input id="sec" type="number" v-model='sec' @input="updateTime"/>
            </b-input-group>
          </b-col>
        </b-row>
      </div> -->
      <div class="d-block text-center">
        <b-btn class="mr-5" variant="success" @click="saveGoal">Save</b-btn>
        <b-btn variant="danger" @click="cancelGoal()">Cancel</b-btn>
      </div>
    </b-modal>
  </b-container>
</template>

<script>

import moment from 'moment'
import { store } from '../store'
import { orderBy } from 'lodash-es'

export default {
  name: 'LiveStatsInput',
  data () {
    return {
      startTime: '',
      timeSet: false,
      localGame: {},
      loading: true,
      statsAvailable: false,
      showStats: false,
      fullscreen: false,
      scoringTeam: null,
      toggleSelected: false,
      interval: false,
      count: 0,
      players: [],
      periods: {'p1': false, 'p2': true, 'p3': true},
      currentPeriod: 'p1',
      goalTags: ['pp', 'sh', 'en', 'ps'],
      currentPenalty: {},
      penaltyTags: ['2', '4', '5', '10']
    }
  },
  computed: {
    team () {
      return store.state.team
    },
    game () {
      return store.state.game
    },
    orderedStats () {
      return orderBy(this.game.stats, ['order'], ['asc'])
    },
    goalieSelect () {
      let goalieSelect = []
      this.team.players.forEach((player) => {
        if (player.position === 'Goalie') {
          goalieSelect.push({
            value: player.jerseyNumber,
            text: player.firstName.substring(0,1) + `. ` + player.lastName + ` (` + player.jerseyNumber + `)`
          })
        }
      })
      return goalieSelect
    }
  },
  async created () {
    window.scrollTo(0,0)
    let gameId = this.$route.params.gameId
    let teamId = this.$route.params.teamId
    this.userProfile = store.state.userProfile
    if (this.userProfile.teams.includes(teamId)) {
      this.isMember = true
      if (this.userProfile.manages.includes(teamId)) {
        this.isManager = true
      }
      await store.commit('setCurrentTeamId', teamId)
      await store.dispatch('bindTeamData')
      // this.team = store.state.team
      if (this.team.name) {
        await store.commit('setCurrentGameId', gameId)
        await store.dispatch('bindGameData')
        await this.initGame(gameId)
        this.setNav()
        this.loading = false
        this.statsAvailable = true
        this.showStats = true
      }
    } else {
      this.isMember = false
      this.loading = false
      console.log('Not a member')
    }
  },
  methods: {
    setNav () {
      store.commit('updateNav', {
        backLink: {
          name: 'TeamDetail',
          params: { teamId: this.team.id }
        },
        teamLink: {
          name: 'TeamDetail',
          params: { teamId: this.team.id }
        },
        teamName: this.team.name
      })
    },
    commitStat (type, team, period, value) {
      // console.log('commitStat', type, team, period, value)
      let field = `stats.` + type + `.` + team + `.` + period
      let data = { field: field, value: value }
      return store.dispatch('updateGameStat', data)
    },
    commitGoalieStat (jerseyNumber, stat, value) {
      // console.log('commitGoalieStat', jerseyNumber, stat, value)
      let field = `goalieStats.` + jerseyNumber + `.` + stat
      let data = { field: field, value: value }
      return store.dispatch('updateGameStat', data)
    },
    commitScoring (team, value, action) {
      // console.log('commitScoring', team, value, action)
      let field = `scoring.` + team
      let data = { field: field, action: action, value: value}
      return store.dispatch('updateGameStat', data)
    },
    commitPenalty (team, value, action) {
      // console.log('commitPenalty', team, value, action)
      let field = `penalties.` + team
      let data = { field: field, action: action, value: value}
      return store.dispatch('updateGameStat', data)
    },
    setPlayers () {
      let players = []
      this.team.players.forEach(player => {
        if (player.position != 'Goalie') {
          players.push(player)
        }
      })
      players.sort(this.jerseySort)
      this.players = players
      // console.log(this.players)
    },
    async initGame () {
      this.setPlayers()
      if (!('stats' in this.game)) {
        this.game.goalieStats = {}
        this.setGoalies()
        this.game.penalties = {
          them: [],
          us: []
        }
        this.game.scoring = {
          them: [],
          us: []
        }
        this.game.stats = {}
        this.team.statCategories.forEach((value, i) => {
          this.game.stats[value.name] = {
            name: value.name,
            order: i,
            us: {
              'p1': 0,
              'p2': 0,
              'p3': 0
            },
            them: {
              'p1': 0,
              'p2': 0,
              'p3': 0
            }
          }
        })
        // console.log(this.game)
        await store.dispatch('saveGame', this.game)
      }
      return
    },
    formatDate (value) {
      if (!value) return ''
      return moment(value.toDate()).format('MMMM D, YYYY')
    },
    jerseySort (a, b) {
      if (parseInt(a.jerseyNumber) < parseInt(b.jerseyNumber))
        return -1
      if (parseInt(a.jerseyNumber) > parseInt(b.jerseyNumber))
        return 1
      return 0
    },
    startGame () {
      let t = new Date()
      this.game.startTime = t
      this.startTime = t.getHours() + ':' + t.getMinutes() + ':' + t.getSeconds()
      this.timeSet = true
    },
    async setGoalies () {
      this.team.players.forEach((player) => {
        if (player.position === 'Goalie') {
          let goalie = {
            name: player.firstName.substring(0,1) + `. ` + player.lastName,
            jerseyNumber: parseInt(player.jerseyNumber),
            shots: 0,
            ga: 0
          }
          this.game.goalieStats[goalie.jerseyNumber] = goalie
          // Remove goalies from player list because they never score, so what's the point?
        }
      })
      this.game.activeGoalie = Object.keys(this.game.goalieStats)[0]
    },
    changePeriod (selectedPeriod) {
      this.currentPeriod = selectedPeriod
      for (var period in this.periods) {
        if (period === selectedPeriod) {
          this.periods[period] = false
        } else {
          this.periods[period] = true
        }
      }
    },
    startPress () {
      if (!this.interval){
        this.interval = setInterval(() => this.count++,30)
      }
    },
    stopPress (type, team, period) {
      if (this.count > 0 && this.count < 10) {
        this.updateStat(type, team, period, 1)
      } else if (this.count > 10) {
        this.updateStat(type, team, period, -1)
      }
      clearInterval(this.interval)
      this.interval = false
      this.count = 0
    },
    updateTime () {
      let currentGoal = this.game.scoring[this.game.scoringTeam][this.game.scoring[this.scoringTeam].length - 1]
      currentGoal.time = this.min + ':' + this.sec
    },
    updateStat (type, team, period, value) {

      if (type !== 'penalties' && type !== 'goals') {
        this.game.stats[type][team][period] += value
        this.commitStat(type, team, period, value)
      }

      if (type === 'shots' && team === 'them') {
        // Attribute shot to the active goalie
        this.game.goalieStats[this.game.activeGoalie].shots += value
        this.commitGoalieStat(this.game.activeGoalie, 'shots', value)
      }

      if (type == 'penalties') {
        if (team == 'us') {
          if (value == 1) {
            this.currentPenalty = {
              team: team,
              details: {
                period: parseInt(period.substring(1,2)),
                jerseyNumber: '',
                minutes: '',
                time: '',
                dt: new Date()
              }
            }
            this.$refs.penaltyModal.show()
          }
          if (value == -1) {
            let lastPenalty = this.game.penalties[team][this.game.penalties[team].length - 1]
            this.game.penalties[team].pop()
            this.commitPenalty(team, lastPenalty, 'remove')
            this.commitStat(type, team, period, -1)
          }
        } else {
          this.commitStat(type, team, period, value)
        }
      }

     // Create the goal object and store as the current goal

      if (type == 'goals') {
        if (value == 1) {
          this.currentGoal = {
            team: team,
            goalDetails: {
              period: parseInt(period.substring(1,2)),
              scored: '',
              assists: [],
              onice: [],
              time: '',
              type: '',
              tags: [],
              dt: new Date()
            }
          }
          this.$refs.goalModal.show()
        }
        if (value == -1) {
          let lastGoal = this.game.scoring[team][this.game.scoring[team].length - 1]
          this.game.scoring[team].pop()
          // Remove the shot
          this.updateStat('shots', team, period, -1)
          // Remove the goal against
          if (team == 'them') {
            this.goalieStats[this.game.activeGoalie].ga -= 1
            this.commitGoalieStat(this.game.activeGoalie, 'ga', -1)
          }
          // Remove the goal from the db
          this.commitScoring(team, lastGoal, 'remove')
        }
      }
    },
    addGoalTag (tag) {
      document.getElementById(tag).classList.toggle('badge-info')
      document.getElementById(tag).classList.toggle('badge-secondary')
      this.goalTags.forEach(t => {
        // Make sure all the other tags are unselected
        if (t != tag) {
          document.getElementById(t).classList.remove('badge-info')
          document.getElementById(t).classList.add('badge-secondary')
        }
      })
      if (this.currentGoal.goalDetails.type === tag) {
        this.currentGoal.goalDetails.type = ''
      } else {
        this.currentGoal.goalDetails.type = tag
      }
    },
    selectPlayerPenalty (jerseyNumber) {
      this.currentPenalty.details.jerseyNumber = jerseyNumber
      this.players.forEach(player => {
        document.getElementById('pen-player-' + player.jerseyNumber).classList.remove('badge-info')
        document.getElementById('pen-player-' + player.jerseyNumber).classList.add('badge-secondary')
      })
      document.getElementById('pen-player-' + jerseyNumber).classList.remove('badge-secondary')
      document.getElementById('pen-player-' + jerseyNumber).classList.add('badge-info')
    },
    selectPenaltyMinutes (min) {
      this.currentPenalty.details.minutes = parseInt(min)
      document.getElementById('pen-' + min).classList.toggle('badge-info')
      document.getElementById('pen-' + min).classList.toggle('badge-secondary')
    },
    addPenalty () {
      this.game.stats['penalties'][this.currentPenalty.team][`p` + this.currentPenalty.details.period] += 1
      this.commitStat('penalties', this.currentPenalty.team, `p` + this.currentPenalty.details.period, 1)
      this.game.penalties[this.currentPenalty.team].push(this.currentPenalty.details)
      this.commitPenalty(this.currentPenalty.team, this.currentPenalty.details, 'add')
      this.$refs.penaltyModal.hide()
      this.clearPenaltyModal()
    },
    cancelPenalty () {
      this.clearPenaltyModal()
      this.$refs.penaltyModal.hide()
    },
    clearPenaltyModal () {
      this.players.forEach(player => {
        document.getElementById('pen-player-' + player.jerseyNumber).classList.remove('badge-info')
        document.getElementById('pen-player-' + player.jerseyNumber).classList.add('badge-secondary')
      })
      this.penaltyTags.forEach(min => {
        document.getElementById('pen-' + min).classList.remove('badge-info')
        document.getElementById('pen-' + min).classList.add('badge-secondary')
      })
    },
    clearGoalModal () {
      this.players.forEach((player) => {
        document.getElementById('goal-player-' + player.jerseyNumber).classList.remove('badge-danger')
        document.getElementById('goal-player-' + player.jerseyNumber).classList.remove('badge-info')
        document.getElementById('goal-player-' + player.jerseyNumber).classList.remove('badge-primary')
        document.getElementById('goal-player-' + player.jerseyNumber).classList.add('badge-secondary')
      })
      this.goalTags.forEach((tag) => {
        document.getElementById(tag).classList.remove('badge-danger')
        document.getElementById(tag).classList.remove('badge-info')
        document.getElementById(tag).classList.remove('badge-primary')
        document.getElementById(tag).classList.add('badge-secondary')
      })
      // this.min = ''
      // this.sec = ''
    },
    incrementPlayer (jerseyNumber) {
      jerseyNumber = parseInt(jerseyNumber)

      // let currentGoal = this.game.scoring[this.scoringTeam][this.game.scoring[this.scoringTeam].length - 1]
      let index

      if (this.currentGoal.goalDetails.scored === jerseyNumber) {
        this.currentGoal.scored = ''
        document.getElementById('goal-player-' + jerseyNumber).classList.remove('badge-danger')
        document.getElementById('goal-player-' + jerseyNumber).classList.add('badge-secondary')
        return
      }

      if (this.currentGoal.goalDetails.assists.indexOf(jerseyNumber) !== -1) {
        this.currentGoal.scored = jerseyNumber
        index = this.currentGoal.goalDetails.assists.indexOf(jerseyNumber)
        this.currentGoal.goalDetails.assists.splice(index, 1)
        document.getElementById('goal-player-' + jerseyNumber).classList.remove('badge-primary')
        document.getElementById('goal-player-' + jerseyNumber).classList.add('badge-danger')
        return
      }

      if (this.currentGoal.goalDetails.onice.indexOf(jerseyNumber) !== -1) {
        this.currentGoal.goalDetails.assists.push(jerseyNumber)
        index = this.currentGoal.goalDetails.onice.indexOf(jerseyNumber)
        this.currentGoal.goalDetails.onice.splice(index, 1)
        document.getElementById('goal-player-' + jerseyNumber).classList.remove('badge-info')
        document.getElementById('goal-player-' + jerseyNumber).classList.add('badge-primary')
        return
      } else {
        this.currentGoal.goalDetails.onice.push(jerseyNumber)
        document.getElementById('goal-player-' + jerseyNumber).classList.add('badge-info')
        document.getElementById('goal-player-' + jerseyNumber).classList.remove('badge-secondary')
      }
    },
    saveGoal () {
      // console.log(this.currentGoal)
      this.game.scoring[this.currentGoal.team].push(this.currentGoal.goalDetails)
      // Add a shot to be helpful
      this.updateStat ('shots', this.currentGoal.team, `p` + this.currentGoal.goalDetails.period, 1)
      // Log the goal
      this.game.stats['goals'][this.currentGoal.team][`p` + this.currentGoal.goalDetails.period] += 1
      this.commitStat ('goals', this.currentGoal.team, `p` + this.currentGoal.goalDetails.period, 1)
      // Attribute GA to home team goalie
      if (this.currentGoal.team == 'them') {
        this.game.goalieStats[this.game.activeGoalie].ga += 1
        this.commitGoalieStat(this.game.activeGoalie, 'ga', 1)
      }
      this.commitScoring(this.currentGoal.team, this.currentGoal.goalDetails, 'add')
      this.$refs.goalModal.hide()
      this.clearGoalModal()
      // Reset button states
      // this.players.forEach((player) => {
      //   document.getElementById('goal-player-' + player.jerseyNumber).classList.remove('badge-primary')
      //   document.getElementById('goal-player-' + player.jerseyNumber).classList.add('badge-secondary')
      // })
    },
    cancelGoal () {
      this.clearGoalModal()
      this.$refs.goalModal.hide()
    },
    submit () {
      this.saveClicked = 1
      this.loading = true
      this.showStats = false
      store.commit('setCurrentGameId', this.game.id)
      store.dispatch('saveGame', this.game).then(() => {
        this.$router.push({
          name: 'GameDetail',
          params: { gameId: this.game.id, teamId: this.team.id }
        })
      })
    }
  }
}
</script>
